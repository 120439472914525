import React from 'react';
import { array } from 'prop-types';
import { Link } from 'gatsby';
import { StoryblokComponent } from 'gatsby-source-storyblok';

// Styles
import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bpWidth } from '../../../styles/Web3.0/variables';

// Components

// Props
const propTypes = {
  shopifyMainProducts: array,
  mainProducts: array,
};

const defaultProps = {
  shopifyMainProducts: [],
  mainProducts: [],
};

const FeatureImageCol = styled(Col)`
  &:first-of-type {
    padding-right: 12px;
  }

  &:last-of-type {
    padding-left: 12px;
  }

  .gatsby-image-wrapper {
    height: 620px;
    border-radius: 20px;
  }

  @media (${bpWidth.desktopSm}) {
    height: 450px;

    &:first-of-type {
      padding-right: 10px;
    }

    &:last-of-type {
      padding-left: 10px;
    }

    .gatsby-image-wrapper {
      height: 450px;
    }
  }

  @media (${bpWidth.tablet}) {
    height: 350px;

    &:first-of-type {
      padding-right: 8px;
    }

    &:last-of-type {
      padding-left: 8px;
    }

    .gatsby-image-wrapper {
      height: 350px;
    }
  }

  @media (${bpWidth.mobile}) {
    height: 275px;

    &:first-of-type {
      padding-right: 5px;
    }

    &:last-of-type {
      padding-left: 5px;
    }

    .gatsby-image-wrapper {
      height: 275px;
    }
  }
`;

const FeaturedImagesRow = (props) => {
  const { shopifyMainProducts, mainProducts } = props;

  return (
    <Row id="featuredImages">
      {shopifyMainProducts.map((shopifyMainProduct) => (
        <FeatureImageCol xs={4} md={5} lg={6} key={shopifyMainProduct.handle}>
          {mainProducts
            .filter((mainProduct) => shopifyMainProduct.handle === mainProduct.handle)
            .map((mainProduct) => {
              const productLink = '/shop/' + shopifyMainProduct.handle;
              return (
                mainProduct.featureImage?.length > 0 && (
                  <Link to={productLink} key={productLink}>
                    <StoryblokComponent blok={mainProduct.featureImage[0]} />
                  </Link>
                )
              );
            })}
        </FeatureImageCol>
      ))}
    </Row>
  );
};

FeaturedImagesRow.propTypes = propTypes;
FeaturedImagesRow.defaultProps = defaultProps;
export default React.memo(FeaturedImagesRow);
