import React, { useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { ErrorMessage, Field, useFormik, FormikProvider } from 'formik';
import Select, { components } from 'react-select';
import * as Yup from 'yup';

import styled from 'styled-components';
import { Col, Row } from 'react-styled-flexboxgrid';
import { GrClose as CloseIcon } from 'react-icons/gr';
import { colors, bpWidth, fontFamilies } from '../../../styles/Web3.0/variables';
import '../../../styles/Web3.0/formStyles.css';

import Chevron from '../../../img/d-arrow-CTA.svg';
import BusinessMenuFooter from '../Header/BusinessMenuFooter';
import { industryOptions, countryOptions, stateOptions } from './ContactUsSelectOptions.js';
import { STATUS_FAILURE, STATUS_INIT, STATUS_SUCCESS } from '../../../common/constants/formikStatus.constant';
import { consentChoiceValue } from '../../../common/constants/privacyPolicyPopup.constant';
import PrivacyPolicyConfirmationPopup from '../../Web3.0/PrivacyPolicy/PrivacyPolicyConfirmationPopup';
import { useBusinessAppState } from '../../../context/business-app-state.context';

const ContactUsRow = styled(Row)`
  background-color: ${colors.navy600};
  display: ${({ contactUsOpen }) => (contactUsOpen ? 'flex' : 'none')};
  height: 100%;
  width: 100%;
  margin-top: 60px;
  overflow-y: scroll;
  position: fixed;
  z-index: 3;
`;

const ContactUsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContactUsFormRow = styled(Row)`
  flex-grow: 1;
  padding-top: 50px;

  @media (${bpWidth.tablet}) {
    padding-top: 60px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 5px;
  }
`;

const ContactUsFormCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InnerFormRow = styled(Row)`
  flex-grow: 1;
`;

const FooterRow = styled(Row)`
  padding-top: 50px;
  min-height: fit-content;
  margin-bottom: 50px;
`;

const FormHeader = styled.h2`
  font-size: 27px;
  font-family: ${fontFamilies.utopiaStdCaptionNormalBoldFontFamily};
  letter-spacing: -0.27px;
  line-height: 1.55;
  color: ${colors.beige100};
  margin-top: -10px;

  @media (${bpWidth.tablet}) {
    font-size: calc(19px + (27 - 19) * ((100vw - 421px) / (768 - 421)));
  }

  @media (${bpWidth.mobile}) {
    font-size: 19px;
    letter-spacing: -0.19px;
    line-height: 1.68;
  }
`;

const Form = styled.form`
  margin-top: 50px;
`;

const ResultMessage = styled.div`
  padding: 0;
  font-size: 18px;
  display: ${({ display }) => display};
  width: 100%;
  font-family: ${fontFamilies.brownNormalFamily};
  font-weight: normal;
  margin-top: 0;
  color: ${colors.navy500};
`;

const FormBodyRow = styled(Row)`
  display: ${({ display }) => display};
  max-width: 750px;
`;

const FormBodyFlexWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  & > FormField {
    flex-basis: 50%;
  }
`;

const FormFooterRow = styled(Row)`
  margin-top: 20px;
  display: ${({ display }) => display};
  flex-direction: column;

  @media (${bpWidth.mobile}) {
    margin-top: 15px;
  }

  p,
  a {
    margin: 0;
    color: ${colors.beige100};
    line-height: 1.7;
    font-size: 10px;
    font-family: ${fontFamilies.brownNormalFamily};

    @media (${bpWidth.tablet}) {
      font-size: calc(8px + (10 - 8) * ((100vw - 421px) / (1025 - 421)));
    }

    @media (${bpWidth.mobile}) {
      font-size: 8px;
    }
  }

  a {
    text-decoration: underline;
  }
`;

const FormField = styled.div`
  flex-basis: 49%;

  &:nth-child(odd) {
    max-width: 310px;
  }

  &:nth-child(n + 3) {
    margin-top: 3em;
  }

  @media (${bpWidth.tablet}) {
    flex-basis: 49%;
  }
`;

const TextField = styled(Field).attrs({
  type: 'text',
})`
  width: 100%;
  min-width: 100px;
  max-width: 375px;
  padding: 0 0 5px 0;
  line-height: 1.7;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid ${colors.beige100};
  color: ${colors.beige100};
  font-family: ${fontFamilies.brownNormalFamily};
  font-size: 14px;

  @media (${bpWidth.tablet}) {
    font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
  }

  @media (${bpWidth.mobile}) {
    font-size: 12px;
  }

  &::placeholder {
    color: ${colors.beige100};
    font-size: 14px;

    @media (${bpWidth.tablet}) {
      font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
    }
    @media (${bpWidth.mobile}) {
      font-size: 12px;
    }
  }
`;

const MessageLabel = styled.div`
  color: ${colors.beige100};
  font-family: ${fontFamilies.brownNormalFamily};
  font-size: 14px;
  line-height: 1.7;
  margin-top: 3.5em;

  @media (${bpWidth.tablet}) {
    font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
  }

  @media (${bpWidth.mobile}) {
    font-size: 12px;
    margin-top: 2.5em;
  }
`;

const Message = styled(Field)`
  box-sizing: border-box;
  width: 100%;
  max-width: none;
  min-height: 125px;
  padding: 14px;
  color: ${colors.beige100};
  font-family: ${fontFamilies.brownNormalFamily};
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid ${colors.beige100};
  background-color: transparent;
  line-height: 1.7;

  @media (${bpWidth.tablet}) {
    font-size: calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)));
  }

  @media (${bpWidth.mobile}) {
    font-size: 12px;
  }
`;

const ErrorStyles = styled.div`
  font-size: 12px;
  line-height: 2;
  color: #d94c1a;
  font-family: ${fontFamilies.brownNormalFamily};
  margin-top: 5px;
`;

const FirstName = styled(TextField)``;
const LastName = styled(TextField)``;
const Email = styled(TextField)``;
const Phone = styled(TextField)``;
const JobTitle = styled(TextField)``;

const DropdownIndicator = (props) => {
  const { selectProps } = props;

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Chevron
          className="chevron"
          style={{
            transitionDuration: '.1s',
            transform: selectProps?.menuIsOpen ? 'rotate(180deg)' : 'none',
            opacity: selectProps?.isDisabled ? '.5' : '1',
          }}
        />
      </components.DropdownIndicator>
    )
  );
};
DropdownIndicator.propTypes = {
  selectProps: shape({
    menuIsOpen: bool,
    isDisabled: bool,
  }),
};

const selectStyles = {
  option: (provided) => ({
    color: colors.beige100,
    marginTop: '1em',
    fontFamily: fontFamilies.brownFontFamily,
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '14px',
    '@media(max-width: 1024px)': {
      ...provided['@media(max-width: 1024px)'],
      fontSize: 'calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)))',
    },
    '@media(max-width: 420px)': {
      ...provided['@media(max-width: 420px)'],
      fontSize: '12px',
    },
  }),
  control: (provided, state) => ({
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${state.isDisabled ? '#f2f1ee80' : colors.beige100}`,
    height: '30px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.beige100,
    fontFamily: fontFamilies.brownFontFamily,
    fontWeight: 'normal',
    fontSize: '14px !important',
    opacity: state.isDisabled ? '.5' : '1',
    paddingBottom: '5px',
    '@media(max-width: 1024px)': {
      ...provided['@media(max-width: 1024px)'],
      fontSize: 'calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421))) !important',
    },
    '@media(max-width: 420px)': {
      ...provided['@media(max-width: 420px)'],
      fontSize: '12px !important',
    },
  }),
  menu: () => ({
    backgroundColor: colors.navy600,
    boxShadow: 'none',
    position: 'absolute',
    height: '235px',
    width: 'calc(100% + 4px)',
    left: '-2px',
    zIndex: '1',
  }),
  menuList: () => ({
    backgroundColor: colors.navy600,
    position: 'absolute',
    top: '0',
    left: '0',
    height: 'auto',
    maxHeight: '235px',
    overflow: 'scroll',
    paddingLeft: '2px',
    width: '100%',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
    boxSizing: 'content-box',
    position: 'relative',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: colors.beige100,
    fontFamily: fontFamilies.brownFontFamily,
    fontWeight: 'normal',
    fontSize: '14px !important',
    lineHeight: '1.7',
    opacity: state.isDisabled ? '.5' : '1',
    paddingBottom: '5px',
    '@media(max-width: 1024px)': {
      ...provided['@media(max-width: 1024px)'],
      fontSize: 'calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421))) !important',
    },
    '@media(max-width: 420px)': {
      ...provided['@media(max-width: 420px)'],
      fontSize: '12px !important',
    },
  }),
  input: (provided) => ({
    ...provided,
    color: colors.beige100,
    fontFamily: fontFamilies.brownFontFamily,
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '1.7',
    margin: '0',
    padding: '0',
    '@media(max-width: 1024px)': {
      ...provided['@media(max-width: 1024px)'],
      fontSize: 'calc(12px + (14 - 12) * ((100vw - 421px) / (1025 - 421)))',
    },
    '@media(max-width: 420px)': {
      ...provided['@media(max-width: 420px)'],
      fontSize: '12px',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: () => ({
    marginRight: '5px',
    width: '10px',
    height: 'auto',
  }),
  noOptionsMessage: () => ({
    fontSize: '12px',
    lineHeight: '2',
    color: '#D94C1A',
    fontFamily: fontFamilies.brownFontFamily,
    marginTop: '5px',
  }),
};

const SelectInputIndustry = ({ setFieldValue }) => (
  <Select
    components={{ DropdownIndicator }}
    options={industryOptions}
    styles={selectStyles}
    placeholder="Industry*"
    onChange={(selectedValue) => {
      setFieldValue('b2b_industry', selectedValue.value);
    }}
    classNamePrefix="react-select"
    // you can set this to true for debugging menu styling
    defaultMenuIsOpen={false}
  />
);
SelectInputIndustry.propTypes = {
  setFieldValue: func.isRequired,
};

const SelectInputCountry = ({ setFieldValue, setCountryIsUSA }) => (
  <Select
    components={{ DropdownIndicator }}
    options={countryOptions}
    styles={selectStyles}
    placeholder="Country*"
    onChange={(selectedValue) => {
      setFieldValue('country', selectedValue.value);
      if (selectedValue.value === 'United States of America') {
        setCountryIsUSA(true);
      } else {
        setCountryIsUSA(false);
        setFieldValue('state', '');
      }
    }}
    classNamePrefix="react-select"
    // you can set this to true for debugging menu styling
    defaultMenuIsOpen={false}
  />
);
SelectInputCountry.propTypes = {
  setFieldValue: func.isRequired,
  setCountryIsUSA: func.isRequired,
};

const SelectInputState = ({ setFieldValue, isDisabled }) => (
  <Select
    components={{ DropdownIndicator }}
    options={stateOptions}
    styles={selectStyles}
    placeholder="State*"
    onChange={(selectedValue) => {
      setFieldValue('state', selectedValue.value);
    }}
    classNamePrefix="react-select"
    // you can set this to true for debugging menu styling
    defaultMenuIsOpen={false}
    isDisabled={isDisabled}
  />
);
SelectInputState.propTypes = {
  setFieldValue: func.isRequired,
  isDisabled: bool,
};

const MessageHeader = styled.h1`
  font-size: 27px;
  line-height: 1.55;
  letter-spacing: -0.27px;
  font-family: ${fontFamilies.utopiaStdCaptionNormalBoldFontFamily};
  color: ${colors.beige100};
  width: 100%;
  margin: 0;
  @media (${bpWidth.tablet}) {
    font-size: calc(19px + (27 - 19) * ((100vw - 421px) / (1024 - 421)));
  }
  @media (${bpWidth.mobile}) {
    font-size: 19px;
  }
`;

const MessageBody = styled.p`
  font-size: 19px;
  line-height: 1.68;
  letter-spacing: -0.19px;
  font-family: ${fontFamilies.utopiaStdCaptionNormalBoldFontFamily};
  color: ${colors.beige100};
  width: 100%;
  margin: 10px 0 0;
  @media (${bpWidth.tablet}) {
    font-size: calc(14px + (19 - 14) * ((100vw - 421px) / (1024 - 421)));
  }
  @media (${bpWidth.mobile}) {
    font-size: 14px;
    width: 82%;
  }
`;

const FormSubmitRow = styled(Row)`
  margin-top: 50px;
  display: ${({ display }) => display};

  @media (${bpWidth.mobile}) {
    margin-top: 35px;
  }
`;

const SubmitButton = styled.button.attrs({
  type: 'submit',
})`
  border-radius: 50px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 250ms;
  width: auto;
  max-width: 250px;
  padding: 12px 45px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 2.88px;
  display: inline-block;
  border: 0;

  color: ${colors.beige100};
  background: ${colors.cerulean400};

  // prevent 2nd line
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${colors.beige100};
    background: ${colors.cerulean700};
  }

  @media (${bpWidth.desktopSm}) {
    padding: 10px 38px;
    font-size: 14px;
  }

  @media (${bpWidth.tablet}) {
    padding: 11px 35px 10px;
    font-size: 16px;
  }

  @media (${bpWidth.mobile}) {
    padding: 11px 25px 10px;
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: 2.16px;
  }
`;

const SuccessMessage = () => (
  <>
    <MessageHeader>Thank you!</MessageHeader>
    <MessageBody>One of our solution specialists will be in touch with you soon.</MessageBody>
  </>
);

const FailureMessage = () => (
  <>
    <MessageHeader>We're sorry.</MessageHeader>
    <MessageBody>There’s been an error in submitting your form. Please try again later.</MessageBody>
  </>
);

const BusinessContact = () => {
  const { contactFormIsOpen, toggleContactForm } = useBusinessAppState();
  const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

  const [countryIsUSA, setCountryIsUSA] = useState(false);
  const [openPrivacyPolicyPopup, setOpenPrivacyPolicyPopup] = useState(false);

  const formik = useFormik({
    initialStatus: STATUS_INIT,
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      jobtitle: '',
      b2b_industry: '',
      country: '',
      state: '',
      website_inbound_message: '',
      consent: 0,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      phone: Yup.string().required('Phone number is required'),
      jobtitle: Yup.string().required('Title is required'),
      b2b_industry: Yup.string().required('Industry is required'),
      country: Yup.string().required('Country is required'),
      state: Yup.string().when('country', {
        is: (val) => val === 'United States of America',
        then: Yup.string().required('State is required'),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: (values) => {
      if (!values.consent) {
        setOpenPrivacyPolicyPopup(true);
        return;
      }

      setOpenPrivacyPolicyPopup(false);

      const data = {
        fields: [
          {
            name: 'firstname',
            value: values.firstname,
          },
          {
            name: 'lastname',
            value: values.lastname,
          },
          {
            name: 'email',
            value: values.email,
          },
          {
            name: 'phone',
            value: values.phone,
          },
          {
            name: 'jobtitle',
            value: values.jobtitle,
          },
          {
            name: 'b2b_industry',
            value: values.b2b_industry,
          },
          {
            name: 'country',
            value: values.country,
          },
          {
            name: 'website_inbound_message',
            value: values.website_inbound_message,
          },
        ],
        context: {
          pageName: 'B2B website',
          pageUri: window.location.href,
        },
      };

      if (countryIsUSA) {
        data.fields.push({
          name: 'state',
          value: values.state,
        });
      }

      // Add hubspot tracking cookie if available
      // eslint-disable-next-line no-useless-escape
      const hubspotTrackingCookie = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      if (hubspotTrackingCookie !== '') data.context.hutk = hubspotTrackingCookie;

      // staging
      let url =
        'https://api.hsforms.com/submissions/v3/integration/submit/6286664/acc8226e-8859-43e7-9b32-9021245c2452';

      if (activeEnv === 'production') {
        // prod
        url = 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/a3cd5ebb-45d7-48b5-933f-a46208447675';
      }

      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => {
          // response.ok checks if the response.status is in the range 200-299, inclusive
          formik.resetForm();
          formik.setStatus(response.ok ? STATUS_SUCCESS : STATUS_FAILURE);
        })
        .catch(() => {
          formik.setStatus(STATUS_FAILURE);
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const onChoosingConsent = (choice) => {
    if (choice === consentChoiceValue.consent) {
      formik.values.consent = choice;
      formik.handleSubmit();
    } else {
      setOpenPrivacyPolicyPopup(false);
      formik.setSubmitting(false);
    }
  };

  const onClose = () => {
    formik.resetForm();
    toggleContactForm();
  };

  return (
    <>
      <ContactUsRow contactUsOpen={contactFormIsOpen}>
        <ContactUsCol xs={8} md={10} lg={12}>
          {/* form row */}
          <ContactUsFormRow>
            <ContactUsFormCol xs={8} md={10} lg={12}>
              <Row>
                <Col xsOffset={1} mdOffset={3} xs={4} md={3} lg={4}>
                  <FormHeader>We are looking forward to connecting with you!</FormHeader>
                </Col>

                <Col xsOffset={1} mdOffset={0} xs={1}>
                  <Row end="xs">
                    <CloseIcon className="closeIcon" onClick={onClose} />
                  </Row>
                </Col>
              </Row>

              <InnerFormRow>
                <Col xsOffset={1} mdOffset={3} xs={6} md={4} lg={4}>
                  <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                      <ResultMessage display={formik.status === STATUS_INIT ? 'none' : 'block'}>
                        {formik.status === STATUS_SUCCESS && <SuccessMessage />}
                        {formik.status === STATUS_FAILURE && <FailureMessage />}
                      </ResultMessage>

                      <FormBodyRow display={formik.status === STATUS_INIT ? 'flex' : 'none'}>
                        {/* begin form fields left column */}
                        <Col xs={8} md={10} lg={12}>
                          <FormBodyFlexWrapper>
                            <FormField>
                              <FirstName name="firstname" placeholder="First name*" />
                              <ErrorStyles>
                                <ErrorMessage name="firstname" />
                              </ErrorStyles>
                            </FormField>

                            <FormField>
                              <LastName name="lastname" placeholder="Last name*" />
                              <ErrorStyles>
                                <ErrorMessage name="lastname" />
                              </ErrorStyles>
                            </FormField>

                            <FormField>
                              <Email name="email" placeholder="Email*" />
                              <ErrorStyles>
                                <ErrorMessage name="email" />
                              </ErrorStyles>
                            </FormField>

                            <FormField>
                              <Phone name="phone" placeholder="Phone*" />
                              <ErrorStyles>
                                <ErrorMessage name="phone" />
                              </ErrorStyles>
                            </FormField>

                            <FormField>
                              <JobTitle name="jobtitle" placeholder="Title*" />
                              <ErrorStyles>
                                <ErrorMessage name="jobtitle" />
                              </ErrorStyles>
                            </FormField>

                            <FormField>
                              <Field name="b2b_industry">{({ form }) => <SelectInputIndustry {...form} />}</Field>
                              <ErrorStyles>
                                <ErrorMessage name="b2b_industry" />
                              </ErrorStyles>
                            </FormField>

                            <FormField>
                              <Field name="country">
                                {({ form }) => <SelectInputCountry setCountryIsUSA={setCountryIsUSA} {...form} />}
                              </Field>
                              <ErrorStyles>
                                <ErrorMessage name="country" />
                              </ErrorStyles>
                            </FormField>

                            <FormField>
                              <Field name="state">
                                {({ form }) => <SelectInputState isDisabled={!countryIsUSA} {...form} />}
                              </Field>
                              <ErrorStyles>
                                <ErrorMessage name="state" />
                              </ErrorStyles>
                            </FormField>
                          </FormBodyFlexWrapper>
                        </Col>

                        <Col xs={8} md={10} lg={12}>
                          <MessageLabel>Tell us about your questions/requests/comments</MessageLabel>

                          <Message name="website_inbound_message" component="textarea" />

                          <ErrorStyles>
                            <ErrorMessage name="website_inbound_message" />
                          </ErrorStyles>
                        </Col>
                      </FormBodyRow>

                      <FormSubmitRow display={formik.status === STATUS_INIT ? 'block' : 'none'}>
                        <SubmitButton>SUBMIT</SubmitButton>
                      </FormSubmitRow>

                      <FormFooterRow display={formik.status === STATUS_INIT ? 'flex' : 'none'}>
                        <p>By connecting with us, you agree to</p>
                        <p>
                          Piaggio Fast Forward’s{' '}
                          <a href="/privacy-policy" target="_blank" rel="noreferrer">
                            Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a href="/terms-of-use" target="_blank" rel="noreferrer">
                            Terms of Use
                          </a>
                          .
                        </p>
                      </FormFooterRow>
                    </Form>
                  </FormikProvider>
                </Col>
              </InnerFormRow>

              <FooterRow>
                <Col xs={8} md={10} lg={12}>
                  <BusinessMenuFooter />
                </Col>
              </FooterRow>
            </ContactUsFormCol>
          </ContactUsFormRow>
        </ContactUsCol>
      </ContactUsRow>
      {openPrivacyPolicyPopup && <PrivacyPolicyConfirmationPopup onPopUpClose={onChoosingConsent} lightMode={false} />}
    </>
  );
};

BusinessContact.propTypes = {};
BusinessContact.defaultProps = {};

export default BusinessContact;
