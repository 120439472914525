import React, { useEffect } from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';

// Styles
import { bodyLinkStyles, bodySRegularBrownStyles, bodyXxsRegularBrownStyles } from '../../../styles/Web3.0/typography';
import { bpWidth } from '../../../styles/Web3.0/variables';

const AffirmCopy = styled.p`
  ${bodySRegularBrownStyles};
  padding-bottom: 10px;

  a.affirm-modal-trigger {
    ${bodyLinkStyles};
  }

  @media (${bpWidth.desktopSm}) {
    ${bodyXxsRegularBrownStyles}
  }
`;

const ProductVariantAffirm = (props) => {
  const { currentVariant = null } = props;

  useEffect(() => {
    if (window.affirm && window.affirm.ui.ready() && window.affirm.jsReady() && currentVariant) {
      if (currentVariant) {
        const options = {
          pageType: 'payment',
          // The product price amount passed to display loan price specific messaging. Value in USD cents (e.g. $100 = 10000)
          amount: (currentVariant.price || 0) * 100,
        };

        const affirmVariantComponent = window.affirm.ui.components.create('promo', options);
        setTimeout(() => affirmVariantComponent.render('.variantAffirm'), 500);
      }
    }
  }, [currentVariant]);

  return <AffirmCopy className="variantAffirm" />;
};

ProductVariantAffirm.propTypes = {
  currentVariant: object,
};
ProductVariantAffirm.defaultProps = {};
export default React.memo(ProductVariantAffirm);
