import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import Imgix from 'react-imgix';
import styled from 'styled-components';
import ArrowDown from '../../../icons/Web3.0/arrow-down.svg';
import { colors } from '../../../styles/Web3.0/variables';
import { buttonSStyles } from '../../../styles/Web3.0/typography';

const ImgixCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImgixAssetRow = styled(Row)`
  width: 200px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ hasBorder = false }) => (hasBorder ? `1px solid ${colors.gray200}` : undefined)};
  background-color: ${({ inverse = false }) => (inverse ? colors.black : undefined)};
`;

const DownloadButtonRow = styled(Row)`
  svg {
    ${buttonSStyles};
    cursor: pointer;
    color: ${colors.cerulean400};
    background-color: ${colors.beige100};
    margin: 5px 0;
    width: 100%;
    height: 20px;
    min-height: 20px;

    &:hover {
      color: ${colors.cerulean700};
    }
  }
`;

const MediaKit = ({ blok }) => {
  const downloadHandler = (url, title) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', url);
        if (title) {
          link.download = title;
        }
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.error(e.message));
  };

  return blok.asset?.filename ? (
    <ImgixCol {...storyblokEditable(blok)} xs={8} sm={4} md={3} lg={3}>
      <a href={blok.asset?.filename} target="_blank" rel="noreferrer">
        <ImgixAssetRow inverse={blok.inverse} hasBorder={blok.hasBorder}>
          <Imgix
            src={blok.asset?.filename}
            imgixParams={{
              fit: blok.fit,
            }}
            htmlAttributes={{
              alt: blok.altText,
              title: blok.title,
            }}
            width={160}
          />
        </ImgixAssetRow>
      </a>
      <DownloadButtonRow>
        <ArrowDown onClick={() => downloadHandler(blok.asset.filename, blok.title)} />
      </DownloadButtonRow>
    </ImgixCol>
  ) : undefined;
};

MediaKit.propTypes = {
  blok: object.isRequired,
};

export default MediaKit;
