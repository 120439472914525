import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled, { css } from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import TabHeader from './TabHeader';
import TabContentRow from './TabContentRow';
import { useIsomorphicLayoutEffect } from '../../../hooks';

const TabHeaderRow = styled(Row)`
  ${({ stickyTop }) =>
    stickyTop
      ? css`
          position: sticky;
          top: ${({ headerHeight }) => `${headerHeight}px`};
          z-index: 4;
        `
      : undefined};
`;

const Tabs = ({ blok }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [stickyTopPosition, setStickyTopPosition] = useState(61);
  const customCssClass = useMemo(() => {
    return ['tabs-component', blok.customCssClass].filter(Boolean).join(' ');
  }, [blok.customCssClass]);
  const { viewWidth } = useViewport();

  const calculateStickyTopPosition = () => {
    const headerContainerRow = document.getElementById('headerContainerRow');
    if (headerContainerRow) {
      setStickyTopPosition(headerContainerRow.getBoundingClientRect().height - 1);
    }
  };

  useIsomorphicLayoutEffect(() => {
    setTimeout(calculateStickyTopPosition, 1000);
  }, [viewWidth]);

  useIsomorphicLayoutEffect(() => {
    if (!activeTab && blok.items?.length > 0) {
      const [firstItem] = blok.items;
      setActiveTab(firstItem._uid);
    }
  }, [blok.items]);

  return (
    blok.items?.length > 0 && (
      <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12} className={customCssClass}>
        <>
          <TabHeaderRow
            stickyTop={blok.stickyTop}
            headerHeight={stickyTopPosition}
            className="tabs-component--header-row"
          >
            {(blok.items || []).map((nestedBlok) => (
              <TabHeader
                uid={nestedBlok._uid}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                title={nestedBlok.heading}
                mobileTitle={nestedBlok.mobileHeading}
                key={nestedBlok._uid}
              />
            ))}
          </TabHeaderRow>
          {(blok.items || []).map((nestedBlok) => (
            <TabContentRow
              uid={nestedBlok._uid}
              activeTab={activeTab}
              key={nestedBlok._uid}
              className="tabs-component--content-row"
            >
              <StoryblokComponent blok={nestedBlok} stickyTop={blok.stickyTop} />
            </TabContentRow>
          ))}
        </>
      </Col>
    )
  );
};

Tabs.propTypes = {
  blok: object.isRequired,
};

export default Tabs;
