import React, { useCallback, useEffect, useState } from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { Col, Row } from 'react-styled-flexboxgrid';
import { object } from 'prop-types';
import styled from 'styled-components';
import FilterDropdown from '../press/FilterDropdown';
import { colors } from '../../../styles/Web3.0/variables';

const FilterRow = styled(Row)`
  position: sticky;
  top: 100px;
  background-color: ${colors.beige100};
  width: 100%;
  padding-bottom: 1.8em;
`;

const AccordionList = ({ blok }) => {
  const { items = [], darkMode = false, hasFilter = false } = blok;
  const [accordionList, setAccordionList] = useState([]);
  const [filteredList, setFilteredList] = useState([...accordionList]);
  /**
   * @type {DatasourceEntry[]}
   */
  const categoriesInitial = [];
  const [categories, setCategories] = useState(categoriesInitial);

  const filterClickHandler = (value) => {
    const filteredListData = value === 'all' ? [...accordionList] : accordionList.filter((item) => item._uid === value);
    setFilteredList(filteredListData);
  };

  useEffect(() => {
    setAccordionList(items);

    /**
     * @type {DatasourceEntry[]}
     */
    const categoriesData = items.map((item) => {
      return {
        id: item._uid,
        name: item.heading,
        value: item._uid,
        dimension_value: null,
      };
    });
    setCategories(categoriesData);
  }, [items]);

  useEffect(() => {
    filterClickHandler('all');
  }, [accordionList]);

  const handleExpand = useCallback(
    (_uid) => {
      const newValueItems = (filteredList || []).map((value) => {
        if (value._uid === _uid) {
          return {
            ...value,
            isExpand: !value.isExpand,
          };
        }

        return value;
      });

      setFilteredList(newValueItems);
    },
    [accordionList, filteredList]
  );

  return accordionList?.length ? (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {hasFilter && (
        <FilterRow>
          <FilterDropdown optionList={categories} onFilter={filterClickHandler} />
        </FilterRow>
      )}
      <Row>
        {filteredList.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} onExpand={handleExpand} darkMode={darkMode} />
        ))}
      </Row>
    </Col>
  ) : undefined;
};

AccordionList.propTypes = {
  blok: object.isRequired,
};

export default AccordionList;
