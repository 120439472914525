import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { bool, object } from 'prop-types';
import styled, { css } from 'styled-components';
import FilterDropdown from './FilterDropdown';
import Chevron from '../../../icons/Web3.0/arrow-DCerulean.svg';
import { bpWidth, colors } from '../../../styles/Web3.0/variables';
import { useStoryblokDatasourceEntries } from '../../../hooks';
import { bodySRegularBrownStyles } from '../../../styles/Web3.0/typography';
import Pagination from '../../Web3.0/Pagination/Pagination';

const FilterRow = styled(Row)`
  ${({ stickyTop }) =>
    stickyTop
      ? css`
          position: sticky;
          top: 100px;
        `
      : undefined};
  background-color: ${colors.beige100};
  width: 100%;
  padding-bottom: 30px;
`;

const SortButtonCol = styled(Col)`
  margin-top: 50px;
`;

const SortButton = styled.button`
  cursor: pointer;
  ${bodySRegularBrownStyles};
  background: transparent;
  text-align: start;
  border: none;
`;

const DescRow = styled(Row)`
  svg {
    margin-top: 11px;
    max-width: 12px;
    height: 6px;
    margin-right: 0.2em;
    transform: rotate(180deg);

    path#Path_2 {
      stroke: ${(props) => (props.isActive ? colors.cerulean500 : colors.beige700)} !important;
    }
  }

  ${SortButton} {
    color: ${(props) => (props.isActive ? colors.gray700 : colors.beige700)} !important;
  }
`;

const AscRow = styled(Row)`
  svg {
    margin-top: 11px;
    max-width: 12px;
    height: 6px;
    margin-right: 0.2em;

    path#Path_2 {
      stroke: ${(props) => (props.isActive ? colors.cerulean500 : colors.beige700)} !important;
    }
  }

  ${SortButton} {
    color: ${(props) => (props.isActive ? colors.gray700 : colors.beige700)} !important;
  }
`;

const PostWrapper = styled(Col)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;

  @media (${bpWidth.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (${bpWidth.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const PaginationCol = styled(Col)`
  padding: 0;
  margin: 70px auto;
`;

const PostGrid = ({ blok, stickyTop }) => {
  const categories = useStoryblokDatasourceEntries('press-category');
  const [orderBy, setOrderBy] = useState('desc');
  const [sortedPosts, setSortedPosts] = useState([...(blok.list || [])]);
  const [paginationClass, setPaginationClass] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(6);
  const [postsShown, setPostsShown] = useState(postsPerPage);
  const [posts, setPosts] = useState(sortedPosts.slice(0, postsPerPage));
  const [totalPosts, setTotalPosts] = useState(sortedPosts.length);

  const handleSort = (_posts) => {
    switch (orderBy) {
      case 'desc':
        _posts.sort((a, b) => new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime());
        break;
      case 'asc':
        _posts.sort((a, b) => new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime());
        break;
      default:
    }

    return _posts;
  };

  useEffect(() => {
    const sorted = handleSort([...sortedPosts]);
    setSortedPosts(sorted);
    setPostsShown(postsPerPage);
  }, [orderBy]);

  useEffect(() => {
    setPosts(sortedPosts.slice(0, postsPerPage));
    setTotalPosts(sortedPosts.length);
  }, [sortedPosts]);

  useEffect(() => {
    if (postsShown === 0) {
      setPostsShown(postsPerPage);
    }
  }, []);

  useEffect(() => {
    if (postsShown > postsPerPage && postsShown < totalPosts) {
      setPaginationClass('bothCtas');
    } else if (postsShown > postsPerPage && postsShown >= totalPosts) {
      setPaginationClass('prevCtaOnly');
    } else if (postsShown <= postsPerPage && postsShown < totalPosts) {
      setPaginationClass('nextCtaOnly');
    }
  }, [postsShown]);

  const handleFilterChange = (value) => {
    let filteredData = [...(blok.list || [])];
    if (value !== 'all') {
      filteredData = filteredData.filter((p) => p.category === value);
    }
    const sorted = handleSort(filteredData);
    setSortedPosts(sorted);
    setPostsShown(postsPerPage);
  };

  const previousPosts = () => {
    if (postsShown > postsPerPage) {
      if (postsShown - postsPerPage > 0 && totalPosts !== postsShown) {
        setPosts(sortedPosts.slice(postsShown - 2 * postsPerPage, postsShown - postsPerPage));
        setPostsShown(postsShown - postsPerPage);
      } else if (totalPosts === postsShown) {
        if (totalPosts % postsPerPage !== 0) {
          const remainingPosts = totalPosts % postsPerPage;
          setPosts(sortedPosts.slice(postsShown - postsPerPage - remainingPosts, postsShown - remainingPosts));
          setPostsShown(postsShown - remainingPosts);
        } else {
          setPosts(sortedPosts.slice(postsShown - 2 * postsPerPage, postsShown - postsPerPage));
          setPostsShown(postsShown - postsPerPage);
        }
      }
    }
  };

  const nextPosts = () => {
    if (postsShown < totalPosts) {
      const remainder = totalPosts - postsShown;
      if (remainder > postsPerPage) {
        setPosts(sortedPosts.slice(postsShown, postsShown + postsPerPage));
        setPostsShown(postsShown + postsPerPage);
      } else {
        const remainingPosts = postsShown + (totalPosts - postsShown);
        setPosts(sortedPosts.slice(postsShown, remainingPosts));
        setPostsShown(remainingPosts);
      }
    }
  };

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <FilterRow stickyTop={stickyTop}>
        <FilterDropdown optionList={categories} stickyTop={stickyTop} onFilter={handleFilterChange} />
        <SortButtonCol xsOffset={1} mdOffset={0}>
          <DescRow isActive={orderBy === 'desc'}>
            <Col>
              <Chevron />
            </Col>
            <Col>
              <SortButton onClick={() => setOrderBy('desc')}>Most recent</SortButton>
            </Col>
          </DescRow>
          <AscRow isActive={orderBy === 'asc'}>
            <Col>
              <Chevron />
            </Col>
            <Col>
              <SortButton onClick={() => setOrderBy('asc')}>Oldest</SortButton>
            </Col>
          </AscRow>
        </SortButtonCol>
      </FilterRow>
      <Row>
        <PostWrapper xs={8} md={10} lg={12}>
          {posts.map((nestedBlok) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </Row>
          ))}
        </PostWrapper>
      </Row>
      <Row>
        <PaginationCol xs={8} md={10} lg={12}>
          <Row>
            <Pagination
              paginationClass={paginationClass}
              postsShown={postsShown}
              postsPerPage={postsPerPage}
              totalPosts={totalPosts}
              previousPosts={previousPosts}
              nextPosts={nextPosts}
            />
          </Row>
        </PaginationCol>
      </Row>
    </Col>
  );
};

PostGrid.propTypes = {
  blok: object.isRequired,
  stickyTop: bool,
};

export default PostGrid;
