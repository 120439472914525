import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { initialSettings, settingsReducer } from '../state/settings/businessSettingsReducer';
import { useBoolean } from '../hooks';
import { SCROLL_DOWN, SCROLL_UP } from '../constants';

/**
 * @type {React.Context<BusinessAppState>}
 */
const BusinessAppStateContext = React.createContext({});

/**
 * @typedef {Object} BusinessAppStateSettings
 * @property {boolean} showContactButton
 */

/**
 * @typedef {Object} BusinessAppState
 * @property {BusinessAppStateSettings} settings
 * @property {({ name: string, value: any }) => void} dispatchSetting
 * @property {boolean} contactFormIsOpen
 * @property {() => void} toggleContactForm
 * @property {boolean} hamburgerMenuIsOpen
 * @property {() => void} closeHamburgerMenu
 * @property {() => void} toggleHamburgerMenu
 * @property {*} footerBlok
 * @property {(value: any) => void} setFooterBlok
 */

/**
 * @returns {BusinessAppState}
 */
export const useBusinessAppState = () => {
  /**
   * @type {BusinessAppState}
   */
  const context = useContext(BusinessAppStateContext);
  if (!context) {
    throw new Error('useBusinessAppState must be used within the BusinessAppStateProvider');
  }
  return context;
};

const BusinessAppStateProvider = ({ children }) => {
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [footerBlok, setFooterBlok] = useState(null);
  const { value: contactFormIsOpen, setFalse: hideContactForm, toggle: toggleContactForm } = useBoolean(false);
  const { value: hamburgerMenuIsOpen, setFalse: closeHamburgerMenu, toggle: toggleHamburgerMenu } = useBoolean(false);

  const contextValue = useMemo(
    () => ({
      settings,
      dispatchSetting,
      contactFormIsOpen,
      toggleContactForm,
      hamburgerMenuIsOpen,
      closeHamburgerMenu,
      toggleHamburgerMenu,
      footerBlok,
      setFooterBlok,
    }),
    [settings, contactFormIsOpen, hamburgerMenuIsOpen, footerBlok]
  );

  useEffect(() => {
    if (contactFormIsOpen) {
      closeHamburgerMenu();
    }
  }, [contactFormIsOpen]);

  useEffect(() => {
    if (hamburgerMenuIsOpen) {
      hideContactForm();
    }
  }, [hamburgerMenuIsOpen]);

  useEffect(() => {
    if (!settings.showContactButton) {
      hideContactForm();
    }
  }, [settings.showContactButton]);

  let lastScroll = 0;
  const handleDynamicHeader = () => {
    const { body } = document;
    const currentScroll = window.scrollY;
    if (currentScroll <= 0) {
      body.classList.remove(SCROLL_UP);
      return;
    }
    if (currentScroll > lastScroll && !body.classList.contains(SCROLL_DOWN)) {
      // down
      body.classList.remove(SCROLL_UP);
      body.classList.add(SCROLL_DOWN);
    } else if (currentScroll < lastScroll && body.classList.contains(SCROLL_DOWN)) {
      // up
      body.classList.remove(SCROLL_DOWN);
      body.classList.add(SCROLL_UP);
    }
    lastScroll = currentScroll;
  };

  useEffect(() => {
    handleDynamicHeader();

    window.addEventListener('scroll', handleDynamicHeader);

    return () => {
      window.removeEventListener('scroll', handleDynamicHeader);
    };
  }, []);

  return <BusinessAppStateContext.Provider value={contextValue}>{children}</BusinessAppStateContext.Provider>;
};

BusinessAppStateProvider.propTypes = {
  children: PropTypes.any,
};

export default BusinessAppStateProvider;
